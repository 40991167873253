import { LinkA, LinkScroll } from "../../components/reusable/styled";

/* switch between Newsletter and LogInButton on Hero Academy page*/

export const heroObjThree = {
  title: "Zúčastni se intenzivních Akademií s ReactGirls!",
  buttonText: "ODEBÍRAT NEWSLETTER",
  img: "/images/gallery/akademie.jpeg",
  formJunior:
    "https://docs.google.com/forms/d/e/1FAIpQLSeN6pII2E1Hrb3x8vDOCmYRqOfGqK4vail1KQEi6iI2Zypttw/viewform",
  formSenior:
    "https://docs.google.com/forms/d/e/1FAIpQLSeN6pII2E1Hrb3x8vDOCmYRqOfGqK4vail1KQEi6iI2Zypttw/viewform",
  showNewsletter: false,
  showLogInButton: false,
  isDisabled: false,
};

/* switch between Newsletter and LogInButton on Price Container of Academy page*/

export const acadPrice = {
  price: "",
  form: "https://docs.google.com/forms/d/e/1FAIpQLSeN6pII2E1Hrb3x8vDOCmYRqOfGqK4vail1KQEi6iI2Zypttw/viewform",
  showNewsletter: false,
  showLogInButton: true,
  showBorderBottom: true,
  isDisabled: false,
};

export const infoObjTwo = {
  text: "Naše lekce vedou zkušení lektoři, kteří tě provedou teorií i praxí, a na každé lekci ti budou k dispozici koučové, aby ti pomohli s řešením úkolů a otázkami. Kromě samotného učení klademe důraz také na networking – během našich setkání budeš mít možnost navázat kontakty s dalšími účastnicemi a sdílet své zkušenosti. Lekce v týdnu probíhají ve večerních hodinách, sobotní lekce jsou celodenní. Průběžně budeme doplňovat podrobné informace k jednotlivým akademiím – pro koho jsou určeny, na co se zaměřují, přesné termíny a časy, a také odkazy na registraci. ",
  content: "academy",
};

export const academyCardArr = [
  {
    icon: "/images/icons/programming.svg",
    title: "Programování",
    text: "Seznámení se s programováním v jazyce JavaScript - vhodné pro začátečníky, nebo rovnou Reactem - vhodné pro pokročilejší.",
  },
  {
    icon: "/images/icons/profesional.svg",
    title: "Profesionály",
    text: "Vybrali jsme pro vás ty nejlepší lektory, kteří jsou profesionálové v IT oboru a mají bohaté zkušenosti.",
  },
  {
    icon: "/images/icons/presentation.svg",
    title: "Lekce",
    text: "Zúčastníte se lekcí zaměřených na budování vašich technických dovedností v HTML, CSS, JavaScript nebo React.",
  },
];

export const titleObjTwo = {
  mainStudentTitle: "Komu je Akademie určena?",
  mainCardsTitle: "Co Akademie nabízí?",
  mainPriceTitle: "Podporujeme ženy na cestě do IT!",
  mainCalendarTitile: "Kalendář Akademií",
};

export const acadStudentArr = [
  {
    icon: "/images/icons/website.svg",
    heading: "Zájemkyním",
    text: "Zájemkyním o programováním, které se chtějí naučit tvorbu webových stránek a vytvořit si svou vlastní aplikaci v JavaScript nebo Reactu.",
  },
  {
    icon: "/images/icons/victory.svg",
    heading: "Začátečnicím",
    text: "Začátečnicím, které mají základní nebo žádné zkušenosti s programováním a chtěly by se seznámit se s HTML, CSS, JavaScriptem nebo Reactem.",
  },
  {
    icon: "/images/icons/woman3.svg",
    heading: "Ženám",
    text: "Ženám, které se chtějí naučit frontendové programování a vyzkoušet si získané znalosti v praxi. Zároveň si vyzkouší případné mock interview.",
  },
];

export const acadPriceArrJunior = [
  {
    text: "5 offline lekcí programování a workshop",
  },
  {
    text: "online studijní materiály k akademii",
  },
  {
    text: "certifikát o absolvování",
  },
  {
    text: "možnost vyzkoušet si mock interview s HR",
  },
];

export const acadPriceArrSenior = [
  {
    text: "Naše lekce vedou zkušení lektoři, kteří tě provedou teorií i praxí, a na každé lekci ti budou k dispozici koučové, aby ti pomohli s řešením úkolů a otázkami. Kromě samotného učení klademe důraz také na networking – během našich setkání budeš mít možnost navázat kontakty s dalšími účastnicemi a sdílet své zkušenosti. Lekce v týdnu probíhají ve večerních hodinách, sobotní lekce jsou celodenní. Průběžně budeme doplňovat podrobné informace k jednotlivým akademiím – pro koho jsou určeny, na co se zaměřují, přesné termíny a časy, a také odkazy na registraci.",
    text2: "Těšíme se na Tebe na některé z našich akademií!",
  },
];

export const programArr1Junior = [
  {
    date: "28. května 2024",
    topic: "TypeScript",
    time: "18:00 - 21:00",
    place: "Accolade",
    border: true,
  },
  {
    date: "30. května 2024",
    topic: "TypeScript",
    time: "18:00 - 21:00",
    place: "Accolade",
    border: true,
  },
  {
    date: "1. června 2024",
    topic: "TypeScript",
    time: "9:00 - 17:00",
    place: "Accolade",
    border: true,
  },
];

export const programAcademy = [
  {
    date: "10. 2. (18-21), 12. 2. (18-21), 17. 2. (18-21), 19. 2. (18-21), 22. 2. (9-17)",
    topic: "HTML, CSS",
    description:
      "Nauč se základy HTML a CSS a vytvoř si vlastní jednoduchou webovou stránku. Akademie zahrnuje 5 praktických lekcí, které tě provedou základními principy tvorby webu.",
    location: "Praha",
    registrationDate: "ukončeno",
    price: "850 Kč",
    form: "https://docs.google.com/forms/d/e/1FAIpQLSeqTb9wGDEzKJjp9-TQE59AF1zyLG0XXAJBnPmkcYHhmymsFA/viewform?usp=header",
    schedule: [
      "HTML - struktura dokumentu, seznamy, odkazy, obrázky, tabulky, formuláře, vytvoření půdy pro CSS",
      "CSS - obecný úvod, selektory, pozicování elementů, barvy, druhy jednotek, vlastnosti a řezy písma, moderní layout – flexbox, pseudotřídy",
    ],
  },
  {
    date: "3. 3. (18-21), 5. 3. (18-21), 10. 3. (18-21), 12. 3. (18-21), 15. 3. (9-17)",
    topic: "JavaScript I.",
    description:
      "Začni pracovat s jedním z nejpopulárnějších programovacích jazyků na světě – JavaScriptem. Tato akademie tě naučí jeho základy, včetně práce s proměnnými, funkcemi a událostmi.",
    location: "Praha",
    price: "900 Kč",
    openRegistration: true,
    form: "https://docs.google.com/forms/d/e/1FAIpQLScFyekyCVD4m5RYdsM7-kyKBhZ2Ttg1ikrKISS7xjjJP4zQEg/viewform",
    registrationDate: "od 7.2.",
    schedule: [
      "úvod do programování",
      "datové typy, proměnné",
      "podmíněné vykonání kódu",
      "cykly",
      "funkce",
      "Document Object Model",
      "událostmi řízené programování",
      "pole",
      "objekty",
      "programování jednoduché hry",
    ],
  },
  {
    date: " 26. 3. (18-21), 31. 3. (18-21), 7. 4. (18-21), 9. 4. (18-21), 12. 4. (9-17)",
    topic: "JavaScript II.",
    description:
      "Rozšiř své znalosti JavaScriptu a nauč se pracovat s pokročilejšími koncepty, jako jsou objekty, pole, a jejich metody. Akademie je ideální pro ty, kdo již mají základy.",
    location: "Praha",
    price: "1050 Kč",
    registrationDate: "od 3. 3.",
    schedule: [
      "funkcionální programování v JavaScriptu",
      "vyhledávání v kolekcích dat",
      "funkce fetch a datový typ Promise",
      "asynchronní funkce",
      "základy protokolu HTTP",
      "formát JSON",
      "tvorba aplikace charakteru CRUD",
    ],
  },
  {
    date: " 22. 4. (18-21), 24. 4. (18-21), 28. 4. (18-21), 30. 4. (18-21), 5. 5. (18-21), 7. 5. (18-21), 10. 5. (9-17)",
    topic: "React",
    description:
      "Objev kouzlo knihovny React a nauč se tvořit interaktivní webové aplikace. Akademie tě provede od základů až po tvorbu vlastních komponent a použití stavů.",
    location: "Praha",
    price: "1350 Kč",
    registrationDate: "od 24.3.",
    schedule: [
      "co je to React",
      "tvorba komponent",
      "skládání aplikace z komponent",
      "předávání dat komponentám (props)",
      "stav komponent (useState)",
      "navigace mezi stránkami (React Router)",
      "komunikace se serverem (useEffect, fetch)",
      "kontext (useContext)",
      "tvorba aplikace charakteru CRUD",
    ],
  },
  {
    date: "21. 5. (18-21), 26. 5. (18-21), 28. 5. (18-21), 31. 5. (9-17)",
    topic: "TypeScript",
    description:
      "Získej jistotu v psaní bezpečnějšího a robustnějšího kódu díky TypeScriptu, rozšíření JavaScriptu. Naučíš se pracovat s typy a vytvářet dobře strukturované aplikace.",
    location: "Praha",
    price: "900 Kč",
    registrationDate: "od 28.4.",
    schedule: [
      "proč potřebujeme TypeScript",
      "proměnné a datové typy",
      "funkce, jejich parametry a přetěžování",
      "rozhraní a typy",
      "průniky, sjednocení a aliasy",
      "zúžení typů a kontrola toku",
      "generické typy a utility",
      "typeScript a React",
    ],
  },
];

export const acadQuestionsArr = [
  {
    question: "Pro koho je akademie určena?",
    answer:
      "Akademie je určena pro všechny ženy, které se chtějí rekvalifikovat do IT. Není potřeba mít žádné předchozí zkušenosti s programováním. Vše vás naučíme.",
  },
  {
    question: "Jak se můžu na akademii přihlásit? ",
    answer: (
      <>
        Jakmile bude otevřeno přihlašování na akademii, tak zde bude link na
        registrační formulář, který stačí jen vyplnit. Obratem ti na e-mail
        pošleme všechny nezbytné informace a informace o platbě.
      </>
    ),
  },
  {
    question: "Jak bude Akademie probíhat? ",
    answer: (
      <>
        Akademie bude probíhat prezenčně, nebo online. Materiály budou k
        dispozici na Slacku, Notionu a Google Disku. Pokud je Akademie aktivní,
        v harmonogramu{" "}
        <LinkScroll
          to="program"
          smooth={true}
          duration="700"
          spy={true}
          exact="true"
          offset={-80}
        >
          výše
        </LinkScroll>{" "}
        jsou vypsány časy jednotlivých lekcí.
      </>
    ),
  },
  {
    question: "Co si z Akademie odnesu? ",
    answer:
      "Odneseš si znalosti HTML, CSS, JavaScriptu nebo Reactu. Získáš reálnou zkušenost s kódem a motivaci do dalšího seberozvoje. Budeš mít možnost si vyzkoušet interview se zkušeným HR a dostaneš certifikát o absolvování Akademie a ReactGirls merch. Odneseš si i skvělé strávený čas se super partou!",
  },
  {
    question: "Kde se bude Akademie konat? ",
    answer:
      "Všechny bližší informace ti pošleme e-mailem, akademie se bude konat v kancelářích jednoho z našich partnerů v Praze.",
  },
  {
    question: "Stále si nejsi jistá?",
    answer: (
      <>
        Pokud se chceš ujistit, že je Akademie pro tebe to pravé, případně máš
        ještě další otázky, neváhej nás{" "}
        <LinkA href="/kontakt">kontaktovat</LinkA>.
      </>
    ),
  },
];

export const processArrAcad = [
  {
    number: 1,
    heading: "REGISTRACE",
    text: "V případě, že je momentálně otevřené přihlašování na Akademii, klikni níže na tlačítko REGISTRACE. Pokud aktuálně není registrace otevřena, můžeš odebírat náš Newsletter, kde se o startu dalšího běhu Akademie v předstihu dozvíš.",
    reversed: false,
    last: false,
    first: true,
  },
  {
    number: 2,
    heading: "VYPLŇ FORMULÁŘ",
    text: "Po kliknutí na tlačítko REGISTRACE se objeví formulář. Při vyplňování nezapomeň zejména na popsání své motivace. Kapacita Akademie je omezená, proto s přihlašováním neváhej!",
    reversed: true,
    last: false,
    first: false,
  },
  {
    number: 3,
    heading: "KONTAKTUJEME TĚ",
    text: "Po přihlášení tě kontaktujeme s bližšími informacemi ohledně akademie a platby.",
    reversed: false,
    last: false,
    first: false,
  },
  {
    number: 4,
    heading: "ZAČÍNÁME",
    text: "Konkrétní informace k přípravě na jednotlivé hodiny zasíláme pár dní před každou lekcí.",
    reversed: true,
    last: true,
    first: false,
  },
];
