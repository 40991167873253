import React, { useState } from "react";
import { Wrapper, Container, TitleCenter } from "../styled";
import {
  CalendarWrapper,
  StyledCard,
  StyledContent,
  InfoSection,
  Title,
  ActionSection,
  StyledText,
  CalendarIcon,
} from "./Calendar.elements";

function Calendar({ data, mainCalendarTitile }) {
  const [clickedTopics, setClickedTopics] = useState({});

  const toggleArrow = (topic) => {
    setClickedTopics((prev) => ({
      ...prev,
      [topic]: !prev[topic],
    }));
  };

  return (
    <Container>
      <Wrapper>
        <TitleCenter>{mainCalendarTitile}</TitleCenter>
        {data.map((item) => (
          <CalendarWrapper key={item.topic}>
            <StyledCard className="wow fadeInUp" data-wow-delay=".1s">
              <StyledContent>
                <StyledText>
                  <Title>{item.topic}</Title>
                  <p>{item.description}</p>
                  <div onClick={() => toggleArrow(item.topic)}>
                    <div className="secondary">Osnova</div>
                    {clickedTopics[item.topic] ? (
                      <CalendarIcon src="/images/icons/right-arrow-simple.svg" />
                    ) : (
                      <CalendarIcon src="/images/icons/down-arrow-simple.svg" />
                    )}
                  </div>
                  {clickedTopics[item.topic] && (
                    <ul>
                      {item.schedule.map((scheduleItem, index) => (
                        <li key={index}>{scheduleItem}</li>
                      ))}
                    </ul>
                  )}
                </StyledText>
                <InfoSection>
                  <div>
                    <p>{item.date}</p>
                    <p>{item.location}</p>
                  </div>
                </InfoSection>
                <ActionSection>
                  {item.openRegistration ? (
                    <div className="primary">
                      <a href={item.form} target="blank">
                        Přihlásit se
                      </a>
                    </div>
                  ) : (
                    <div className="disabled">
                      Přihlašování {item.registrationDate}
                    </div>
                  )}
                  <p>{item.price}</p>
                </ActionSection>
              </StyledContent>
            </StyledCard>
          </CalendarWrapper>
        ))}
      </Wrapper>
    </Container>
  );
}

export default Calendar;
